/**
*   Allianz
*/
$allianzPrimaryBlue: #003781;
$allianzSecondaryBlue: #0064AE;
$allianzPrimaryDarkBlue: #002e6b;
$allianzPrimaryTextBlue: #009AE0;

/**
*   Generic Colors
*/
$white: #fff;
$black: #000;
$danger: #dc3545;

/**
*   Panels
*/
$panelBackgroundColor: #fff;
$panelBorderColor: #ddd;
$panelBodyBackgroundColor: #f4f5f6;

/**
*   Forms
*/
$inputColor: #495057;

/**
*   Font, Headers
*/
$primaryFontColor: #333;
$guaranteeHeaderColor: #4d4d4d;
$productDetailsHeaderColor: #777;

/**
*   Buttons
*/
$buttonPrimaryColor: #009AE0;
$buttonPrimaryHoverColor: #008BCC;

/**
*   Navbar
*/
$navbarBorderColor: #ddd;

/**
*   Datepicker
*/
$datePickerCalendarIconColor: #b6bcc2;
$datePickerDayTextColor: #111;
$datePickerBackgroundColor: #e2e6ea;
$datePickerBorderColor: #dae0e5;

/**
*   Back Button
*/
$headerBackButtonPrimaryColor: #333;
$headerBackButtonPrimaryBorderColor: #ccc;
$headerBackButtonIconColor: #aaa;
$headerBackButtonHoverColor: #e6e6e6;
$headerBackButtonHoverBorderColor: #adadad;
$headerBackButtonActiveColor: #d4d4d4;
$headerBackButtonActiveBorderColor: #8c8c8c;

/**
*   Recommended Product Pill
*/
$recommendedPrimaryColor: #007A53;

/**
*   Social Media
*/
$facebook: #3B5998;
$twitter: #1DA1F2;
