@import './colors.scss';

.mat-expansion-panel {
    box-shadow: none !important;
    border: 1px solid $panelBorderColor;
    border-radius: 4px;
    margin-bottom: 4px !important;
    font-size: 1rem;
    overflow: visible !important;

    &.mat-expansion-panel-spacing {
        margin: 16px 0 !important;
    }

    .mat-expansion-panel-header {
        color: $white;
        font-weight: bold;
        border-bottom: 1px solid $panelBorderColor;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;

        &:hover {
            background-color: $allianzPrimaryBlue;
        }

        &-title,&-description {
            color: $white;
        }

        &-description {
            .fill-space {
                flex: 1.1 auto;
            }
        }

        .mat-expansion-indicator::after {
            color: $white;
            border-width: 0 3px 3px 0;
        }
    }

    .mat-expansion-panel-content {
        background-color: $panelBodyBackgroundColor;

        .mat-expansion-panel-body {
            padding: 15px;
            border-top: 1px solid $panelBorderColor;
        }
    }
}
