/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import './styles/scss/_colors';

/*----------------------------- TYPOGRAPHY -----------------------------*/
@font-face {
  font-family: 'PT Sans Narrow';
  src: url("/assets/fonts/PTN57F-webfont.eot");
  src: url("/assets/fonts/PTN57F-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PTN57F-webfont.woff") format("woff"), url("/assets/fonts/PTN57F-webfont.ttf") format("truetype"), url("/assets/fonts/PTN57F-webfont.svg#pt_sans_narrowregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PT Sans';
  src: url("/assets/fonts/PTS55F.ttf") format("truetype");
  src: url("/assets/fonts/PTS55F-webfont.eot");
  src: url("/assets/fonts/PTS55F-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PTS55F-webfont.woff") format("woff"), url("/assets/fonts/PTS55F-webfont.ttf") format("truetype"), url("/assets/fonts/PTS55F-webfont.svg#pt_sans_regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PT Sans Narrow';
  src: url("/assets/fonts/PTN77F.ttf") format("truetype");
  src: url("/assets/fonts/PTN77F-webfont.eot");
  src: url("/assets/fonts/PTN77F-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PTN77F-webfont.woff") format("woff"), url("/assets/fonts/PTN77F-webfont.ttf") format("truetype"), url("/assets/fonts/PTN77F-webfont.svg#pt_sans_narrowbold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'PT Sans';
  src: url('/assets/fonts/PTS75F-webfont.eot');
  src: url('/assets/fonts/PTS75F-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/PTS75F-webfont.woff') format('woff'), url('/assets/fonts/PTS75F-webfont.ttf') format('truetype'), url('/assets/fonts/PTS75F-webfont.svg#pt_sansbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

html, body {
    font-family: 'PT Sans', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    background: $white none;
}

h1, .h1 {
    font-size: 1.75rem;
    margin-top: 0;
}

h2, .h2 {
    font-size: 1.5rem;
    margin-top: 0;
}


h3, .h3 {
    font-size: 1.375rem;
    margin-top: 0;
}


h4, .h4 {
    font-size: 1.25rem;
    margin-top: 0;
}

h5, .h5 {
    font-size: 1rem;
    margin-top: 0;
}

.text-danger {
    color: $danger;
}

label {
    font-weight: bold;

    &.font-normal {
        font-weight: normal;
    }
}

.form-check-inline {
    margin-bottom: 0;
    padding-left: 0;
    input {
        vertical-align: middle;
    }
    .form-check-label {
        padding-left: 2px;
    }
}

.form-check {
    padding-left: 1.25rem;
    .form-check-label {
        padding-left: 0;
        display: inline;
    }
}

.link {
    font-size: .875rem;
    color: $allianzSecondaryBlue;
    cursor: pointer;

    &:hover {
        color: $allianzPrimaryBlue;
    }
}

.mat-mdc-button.add-button {
    font-size: .75rem;
    color: $allianzSecondaryBlue;
    margin-left: -15px;

    i.fa {
        margin-bottom: 2px;
    }

    &:hover {
        color: $allianzPrimaryBlue;
    }
}

.container {
    max-width: 1000px;
}

/*
*   Form Controls, Inputs
*/
.form-control {
    border-radius: 0 !important;
    color: $inputColor;
}
.input-group-addon {
    border-radius: 0 !important;
    color: #495057;
    text-align: center;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
}

button {
    appearance: none;
    letter-spacing: normal !important;
}


/*
*   Form Validation
*/
.validation-error {
    display: block;
    color: $danger;
    margin-top: .25rem;
    font-size: .875rem;

    &__message {
        display: block;
    }
}

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
app-date-picker.ng-invalid.ng-touched input,
p-calendar.ng-invalid.ng-touched input {

    border-color: $danger;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);

}
ng-select.ng-select.custom.ng-invalid.ng-touched {
  border-color: $danger;

  .ng-control,
  .ng-select-container {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
    border-color: $danger;
  }

  .ng-control {
    border-color: $danger;
  }

  &.focused .ng-control {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  }
}


p-calendar {
    &.custom.ng-invalid.ng-touched span.ui-calendar input.ui-inputtext {

        border-color: $danger;
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
    }
    &.custom input.ui-inputtext {
        width: 85% !important;
    }

    a {
        cursor: pointer;
    }
    p-footer {
        .link {
            background-color: rgba(0,0,0,.12);
            margin: 5px;
        }

        button {
            float: right;
         }
    }
}

.p-calendar {
    height: 31px;
    border: 1px solid #ced4da;

    input {
        border: none;
        font-size: 14px;
    }
}

.p-datepicker-trigger {
    background-color: #E9ECEF;
    border-left: 1px solid #ced4da;
    border-top: none;
    border-right: none;
    border-bottom: none;
}

.pi.pi-calendar:before {
    color: #495057;
}

.p-datepicker {
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    table {
        margin: 0;

        td {
            padding: 0 !important;
        }

        thead {
            background-color: #f6f8fa;
            border-bottom: 1px solid #c8c8c8;
            tr th {
                padding: 7px;
                span {
                    font-weight: 600;
                    font-size: 15px;
                }
            }
        }
        tbody tr {
            border-bottom: 1px solid #c8c8c8;

            td span {
                width: auto;
                height: auto;
                padding: 3px 0;
                font-size: 14px;
            }
        }        
    }
}

.p-datepicker-header {
    padding: 0;
    background-color: #f1f2f5;
    border-bottom: 1px solid #c8c8c8;
}

.p-datepicker-group {
    padding: 0;
}

.p-datepicker-today span {
    background: none;
}

.p-datepicker-month, .p-datepicker-year {
    font-weight: 400;
    padding: 4px;
}

.p-icon {
    color: #212529;
}

.p-calendar-w-btn {
    width: 100%;
}

p-footer button span {
    color: #0064ae;
}

// Override primeNG icons to use Font Awesome icons
.pi {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.pi-calendar:before {
        content: "\f073";
    }

    &.pi-chevron-right:before {
        content: "\f105";
    }

    &.pi-chevron-left:before {
        content: "\f104";
    }
}

/**
*   ng-select
*/
ng-select.ng-select.custom {

    &.focused > .ng-control {
        border-color: #80bdff;
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .ng-control, .ng-select-container {
        height: 31px;
        min-height: 31px;
        border-radius: 0px;
        border: 1px solid #ced4da;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

        .ng-clear-zone > .ng-clear {
            color: $primaryFontColor;
            font-size: 1.5rem;
        }

        .ng-arrow-zone > .ng-arrow {
            border-color: $primaryFontColor transparent transparent;
        }

        .ng-value-container {
            .ng-input {
                top: 2px;
            }
            .ng-value .ng-value-label, .ng-input input {
                font-size: 0.875rem;
                color: $primaryFontColor;
            }
        }
    }
}

/*
*   Prime NG Date/time picker
*/
p-calendar.custom {
    width: 100%;

    span.ui-calendar {
        width: 100%;

        .ui-calendar-button, .ui-calendar-button:hover {
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            color: #495057;
            border-radius: 0;
            width: 15%;
        }

        input.ui-inputtext {
            width: 100%;
            height: 31px;
            border-radius: 0;
            border: 1px solid #ced4da;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            font-size: .875rem;
            font-family: inherit;
            padding: 0.25rem 0.8rem;
            color: #495057;
            &:focus {
                border-color: #80bdff;
                outline: none;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
    }
}

/*
*   Material
*/
.mat-expansion-panel-header {
    padding: 15px !important;
}

a[mat-raised-button]:hover, a[mat-button]:hover {
    text-decoration: none;
}

.mat-mdc-tab-body {
    overflow: initial !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
    overflow: initial !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-wrapper {
    overflow: visible !important;
}

@mixin mat-icon-size($size: 24px) {
    font-size: $size;
    height: $size;
    width: $size;
}

.mat-mdc-card {
    box-shadow: none !important;
}

.ngb-dp-header .btn-link {
    border-color: transparent;
    box-shadow: none;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-content {
    overflow: hidden;
}

/*
*   Remove IOS specific button styling
*/
app-dynamic-button {
    -webkit-appearance: none !important;
    appearance: none !important;
    margin-right: 5px;
}

/*
* Popover
*/

.popover {
    max-width: 100%;
    min-width: 344px;
}
